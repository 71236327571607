import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useAppSelector } from "../../hooks";
import { MATCH_TYPE } from "../../types/types";
import Group from "../table/group";
import MatchList from "../matches/matchList";


function Slideshow() {
  const _groups = useAppSelector((state) => state.groups.groups);
  const selectedPulje = useAppSelector((state) => state.puljer.selectedPulje);
  const selectedLocation = useAppSelector((state) => state.locations.locations[0]);
  const locations = useAppSelector(state => state.locations.locations)
  const groups = _groups.filter((group) => (group.pulje as any).id === selectedPulje?.id);
  const groupMatches = useAppSelector((state) => state.matches.groupMatches).filter(
    (match) => match.matchType === MATCH_TYPE.GROUP_MATCH
  );

  return (
    <div style={{ width: "100vw", marginTop: "20px" }}>
      <Fade duration={500} arrows={false}>
        {groups.map((group) =>
          <div>

            <Group groupMatches={groupMatches} group={group} />
            <MatchList
              isMatchesView={true}
              location={selectedLocation}
              selectedLocation={locations[0]}
            />
          </div>
        )}
      </Fade>
    </div>
  );
}

export default Slideshow;
